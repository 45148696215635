import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Helmet } from 'react-helmet'
import DesinscriptionForm from "../components/Common/DesinscriptionForm"

const Desinscription = () => {
    
    return (
        <Layout>
            <Helmet>
                <title>Désinscription</title>
                <meta name="description" content="..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Désinscription"
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Désinscription" 
            />
            <div className="blog-area ptb-100">
                <div className="container">
                    <div className="row">
                        <DesinscriptionForm />
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
}

export default Desinscription;