import React from 'react'
import { Link } from 'gatsby'

const DesinscriptionForm = () => {

    return (
        <div className="ptb-30">
            <div className="container">
                <div className="col-lg-12 col-md-12">
                    <h2 className="subtitle">Je ne souhaite plus faire partie de cette liste</h2>
                    <p>Pour ne plus recevoir de message de notre part, merci de confirmer votre adresse mail et de valider.</p>
                    <div className="col-lg-10 col-md-12">
                        <div className="form-livre-blanc">
                            <form id="contactForm" method="post" action="https://wspk.me/desinscription">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div id="livre-blanc">
                                            <input type="email" name="email" className="form-control" required placeholder="Email *" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 center-btn">
                                        <button type="submit" className="default-btn" style={{background:'red'}}>
                                            <i className="flaticon-tick"></i> 
                                            Se désabonner <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='center pt-30'>
                            <Link to="/">Annuler la demande</Link>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
}

export default DesinscriptionForm;